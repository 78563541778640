import React, { useState, useEffect } from 'react';
import Login from '../components/Login';
import AvatarUi from '../components/AvatarUi';
import LoadingScreen from '../components/LoadingScreen';
import GamePlay from '../components/GamePlay';
import axios from 'axios';
import { Button,Row } from 'antd';
let loadingMessage;
var isMobile = require('detect-touch-device');
let newAvatar;

var a = window.location.search;
var b = a.split("?");
//var url = "https://staging.metaverse.infinityvoid.io/?plotId@63c137d9607c772b35566a28"

let ueLocation;
let ueRotation;
let urlProjectId;
let urlSceneId;
let urlPlotId;


if(b.length>1){
 
  var c = b[1].split("&");
 var d = c[0].split("plotId@");
 urlPlotId = d[1];

}




function HomePage() {

    const [showLoginUi,setShowLoginUi] = useState(true);
    const [showReadyPlayerMeUi,setShowReadyPlayerMeUi] = useState(false);
    const [showLoadingUi,setShowLoadingUi] = useState(false);
    const [showGameUi,setShowGameUi] = useState(false);
    const [currentUser,setCurrentUser] = useState();
    const [avatarUrl,setAvatarUrl] = useState();
    const[socketUrl,setSocketUrl] = useState();
    const[showSessionUi,setShowSessionUi] = useState(false);
    const[uiMessage,setUiMessage]=useState();
    const [sceneId,setSceneId] = useState();
    const [plotId,setPlotId] = useState();
    const[unreaLocation,setUnreaLocation] = useState();
    const[unreaRotation,setUnreaRotation] = useState();
    const[projectType, setProjectType] = useState("infinityGame");
    const[gameType, setGameType] = useState();
    const[unitServerType, setUnitServerType] = useState();
    const [webRTCclient, setWebRTCclient] = useState(null);
    const [projectId,setProjectId] = useState("");
const[closeUeGame,setCloseUeGame] = useState(false);
    const [sendMessageCall, setSendMessagecall] = useState(false);




    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );

useEffect(()=>{

  const fetchInitialData = async()=>{

    
  if(urlPlotId !== undefined){

    console.log(urlPlotId);

    const unitData =  await axios.get(`https://mint.infinityvoid.io/gameApi/masterinventory/${urlPlotId}`)
    console.log(unitData.data.deployedScene.projectId);

    setProjectId(unitData.data.deployedScene.projectId);
    setUnreaLocation(unitData.data.unreaLocation);
    ueLocation=unitData.data.unreaLocation;
    ueRotation=unitData.data.unreaLocation;
    setUnreaRotation(unitData.data.unreaRotation);
  }else{
    setProjectId("66987c3600e9a75f67b62483");
    setProjectType("infinityGame")
  }
  }
  fetchInitialData();

},[urlPlotId])

      const loginDone = (userData)=>{
console.log(projectId);
    console.log(userData);
        setCurrentUser(userData);
        setShowLoginUi(false);
      
    setShowReadyPlayerMeUi(true);

    console.log(projectId);
        }
      
  
    const AvatarSelectDone = async(avatar)=>{
      newAvatar = avatar;
        setAvatarUrl(avatar);
        setShowReadyPlayerMeUi(false);

        console.log(projectId);

        if(urlPlotId == undefined){

          loadingMessage = "Entering Vaikunth";

        let touchDescriptor;
        if(isMobile.isMobile == true){
          touchDescriptor = {
           Istouch: 'True'
         }; 
       }else{
         touchDescriptor = {
           Istouch: 'False'
         };
       }



        var obj={avatarUrl:newAvatar,Istouch:touchDescriptor.Istouch,userId:currentUser.id,projectType:"infinityGame",networkIp:process.env.REACT_APP_CLIENT_IP,unreaLocation:ueLocation,unrealRotation:ueRotation}
        var ele = JSON.stringify(obj);
        setUiMessage(ele);
        setShowGameUi(true);
        setSendMessagecall(true);
        setShowLoadingUi(true);

        }
        else{

       
          setProjectType('userGame');

          
    let touchDescriptor;
    if(isMobile.isMobile == true){
      touchDescriptor = {
       Istouch: 'True'
     }; 
   }else{
     touchDescriptor = {
       Istouch: 'False'
     };
   }
   const unitData =  await axios.get(`https://mint.infinityvoid.io/gameApi/masterinventory/${urlPlotId}`)
   console.log(unitData.data.userLimit);

   if(unitData.data.inventorySetting.entryType == "Public"){

    console.log("public");
    const sceneData = await axios.get(`https://mint.infinityvoid.io/gameApi/builderscene/${unitData.data.deployedScene.id}`)
    console.log(sceneData.data);
      
    loadingMessage = "Entering Parcel "+ unitData.data.name;
    

    if(sceneData.data.projectType == "builderTool"){
      var obj={avatarUrl:newAvatar,Istouch:touchDescriptor.Istouch,userId:currentUser.id,projectType:"userGame",networkIp:unitData.data.deployedScene.networkIp,sceneId:sceneData.data.id}
        var ele = JSON.stringify(obj);
        setUiMessage(ele);
      }
      
      if(sceneData.data.projectType == "SDK"){
        var obj={avatarUrl:newAvatar,Istouch:touchDescriptor.Istouch,userId:currentUser.id,projectType:"userGame",networkIp:unitData.data.deployedScene.networkIp,sceneId:"SDK"}
        var ele = JSON.stringify(obj);
        setUiMessage(ele);
      }

  

    setPlotId(urlPlotId);
    setShowGameUi(true);
    setSceneId(sceneData.data.id);
  
      setSendMessagecall(true);
setShowLoadingUi(true);
    
        }else{
    
          var allowedAddressOrig = unitData.data.inventorySetting.allowedAddress;
      
          const allowedAddress = allowedAddressOrig.map(element => {
            return element.toLowerCase();
          });

          if(allowedAddress.includes(currentUser.walletAddress)){

            const sceneData = await axios.get(`https://mint.infinityvoid.io/gameApi/builderscene/${unitData.data.deployedScene.id}`)
            console.log(sceneData.data);
              
        
        
         
        
            loadingMessage = "Entering Parcel "+ unitData.data.name;
            
        
            if(sceneData.data.projectType == "builderTool"){
              var obj={avatarUrl:newAvatar,Istouch:touchDescriptor.Istouch,userId:currentUser.id,projectType:"userGame",networkIp:unitData.data.deployedScene.networkIp,sceneId:sceneData.data.id}
                var ele = JSON.stringify(obj);
                setUiMessage(ele);
              }
              
              if(sceneData.data.projectType == "SDK"){
                var obj={avatarUrl:newAvatar,Istouch:touchDescriptor.Istouch,userId:currentUser.id,projectType:"userGame",networkIp:unitData.data.deployedScene.networkIp,sceneId:"SDK"}
                var ele = JSON.stringify(obj);
                setUiMessage(ele);
              }
        
          
        
            setPlotId(urlPlotId);
        
            setSceneId(sceneData.data.id);
            setShowGameUi(true);

              setSendMessagecall(true);
        setShowLoadingUi(true);
          }else{
            alert('NOT ALLOWED')
          }
          
        
          console.log(allowedAddress);
    
        }



        }

   }

   const handleUnrealResponse = async(message)=>{

console.log(message);

if(message.unreaLocation !== undefined){

  setCloseUeGame(false);
  setShowLoadingUi(true);


  setProjectId("66987c3600e9a75f67b62483");
  setProjectType("infinityGame")
  
  let touchDescriptor;
  if(isMobile.isMobile == true){
    touchDescriptor = {
     Istouch: 'True'
   }; 
 }else{
   touchDescriptor = {
     Istouch: 'False'
   };
 }

      var unreaLocationNew = message.unreaLocation;
console.log(unreaLocationNew);

      var unreaRotationNew = message.unreaRotation;
      loadingMessage = "Teleporting to Parcel "+ message.name ;

      var obj={avatarUrl:newAvatar,Istouch:touchDescriptor.Istouch,userId:currentUser.id,projectType:"infinityGame",networkIp:process.env.REACT_APP_CLIENT_IP,unreaLocation:unreaLocationNew,unrealRotation:unreaRotationNew}
      var ele = JSON.stringify(obj);
      setUiMessage(ele);
      setShowGameUi(true);
      setSendMessagecall(true);
      setShowLoadingUi(true);

}

    if(message.includes('projectId') == true){
      setCloseUeGame(false);
      setShowLoadingUi(true);
      var url2 = message.split("&");
      console.log(url2);
      var userId = url2[0].split("=")[1];
      var projectIdNew = url2[2].split("=")[1];
      console.log(projectIdNew);
      setProjectId(projectIdNew);
      setProjectType('userGame');
      var scenIdNew = url2[3].split("=")[1];
      var plotIdNew = url2[4].split("=")[1];
      var unreaLocationNew = url2[5].split("unrealocation=")[1];
      var unreaRotationNew = url2[6].split("unreaRotation=")[1];
      setUnreaLocation(unreaLocationNew);
      setUnreaRotation(unreaRotationNew);

      ueLocation=unreaLocationNew;
      ueRotation=unreaLocationNew

      console.log(unreaLocationNew);
    
        var newNetworkIp = url2[7].split("networkIp=")[1];
    console.log(scenIdNew);

    let touchDescriptor;
    if(isMobile.isMobile == true){
      touchDescriptor = {
       Istouch: 'True'
     }; 
   }else{
     touchDescriptor = {
       Istouch: 'False'
     };
   }
  
    const sceneData = await axios.get(`https://mint.infinityvoid.io/gameApi/builderscene/${scenIdNew}`)
    console.log(sceneData.data);
      
    const unitData =  await axios.get(`https://mint.infinityvoid.io/gameApi/masterinventory/${plotIdNew}`)
    console.log(unitData.data.userLimit);

    loadingMessage = "Entering Parcel "+ unitData.data.name;
    
    console.log(avatarUrl);
    console.log(newAvatar);

    if(sceneData.data.projectType == "builderTool"){
      var obj={avatarUrl:newAvatar,Istouch:touchDescriptor.Istouch,userId:userId,projectType:"userGame",networkIp:unitData.data.deployedScene.networkIp,sceneId:scenIdNew}
        var ele = JSON.stringify(obj);
        setUiMessage(ele);
      }
      
      if(sceneData.data.projectType == "SDK"){
        var obj={avatarUrl:newAvatar,Istouch:touchDescriptor.Istouch,userId:userId,projectType:"userGame",networkIp:unitData.data.deployedScene.networkIp,sceneId:"SDK"}
        var ele = JSON.stringify(obj);
        setUiMessage(ele);
      }

  

    setPlotId(plotIdNew);

    setSceneId(scenIdNew);
  
      setSendMessagecall(true);
setShowLoadingUi(true);
 
  
  }


  if(message == "VIDEO NOT START"){
    setShowLoadingUi(false);
    alert("PLEASE TRY AFTER SOMETIME");
  }

    if(message == "closeUi"){
      setSendMessagecall(false);
     setShowLoadingUi(false);
      loadingMessage = "";
    }
    
    
    if(message.includes('https') == true){

      var win = window.open(message, '_blank');

    if(win !==null){
      win.focus();
    }
    }

   }

   const backToGame =async()=>{
    setCloseUeGame(true);
    loadingMessage = "Returning to Vaikunth";
    let touchDescriptor;
    if(isMobile.isMobile == true){
      touchDescriptor = {
       Istouch: 'True'
     }; 
   }else{
     touchDescriptor = {
       Istouch: 'False'
     };
   }

   console.log(unreaLocation);
   console.log(ueLocation);
   setShowLoadingUi(true);
   await delay(4000).then(()=>{
    setProjectId("66987c3600e9a75f67b62483");
    setProjectType("infinityGame")
    var obj={avatarUrl:newAvatar,Istouch:touchDescriptor.Istouch,userId:currentUser&&currentUser.id,projectType:"infinityGame",networkIp:process.env.REACT_APP_CLIENT_IP,unreaLocation:ueLocation,unrealRotation:ueRotation}
    var ele = JSON.stringify(obj);
    setUiMessage(ele);
    setShowGameUi(true);
    setSendMessagecall(true);
 
   })


   }

    return(
        <>
        {showLoginUi?(<>
        <Login parentCallback={loginDone}/>
        </>):(null)}

        {showReadyPlayerMeUi?(<>
        <AvatarUi parentCallback={AvatarSelectDone} currentUser={currentUser}/>
        </>):(null)}




        {showLoadingUi?(<>
        <LoadingScreen loadingMessage={loadingMessage}/>
        </>):(null)}

        {showGameUi?(<>
     
        <GamePlay backToGame={backToGame} plotId={plotId} currentUser={currentUser} homeParentCallback={handleUnrealResponse} closeGame={closeUeGame} UiMessage={uiMessage} projectType={projectType} projectId={projectId} sendMessageCall={sendMessageCall}/>
        
   
        </>):(null)}

        </>
    )

}

export default HomePage;
